.iconIng {
  width: 80px;
  height: 80px;
  user-select: none;
}

.ingElmCont {
  height: 512px;
}

.ingList {
  list-style-type: none;
  padding: 0;
}

.ingList li {
  padding: 8px;
  display: inline-block;
  user-select: none;
}

.ingRnd {
  width: 80px;
  height: 80px;
  z-index: 2;
  background-size: contain;
  position: relative;
}

.bolIcon {
  position: relative;
  height: 336px;
  width: 512px;
  border-radius: 40px;
  border: 20px solid #ffbf4b;
  background: #ffbf4b;
  box-sizing: content-box;
  margin: 0 auto 20px;
}

.bolf {
  position: absolute;
  top: 0;
  z-index: 10;
  pointer-events: none;
  opacity: 0.8;
}

.bolb {
  position: absolute;
  top: 0;
  z-index: 0;
  pointer-events: none;
}

.rct-flexCont {
  display: flex;
  flex-direction: row;
  position: relative;
}

.rct-flexCont > div {
  flex: 1;
}

#ingListCont {
  width: 30%;
}

#ingListWrapper {
  background: #fdeab3;
  border-radius: 16px;
  padding: 20px;
  width: 80%;
  margin: auto;
}

.recetteName {
  font-size: 32px;
  color: #fff;
  border: none;
  outline: none;
  text-align: center;
  width: 59%;
  display: block;
  margin: 0 auto 20px;
  background: #ffbf4b;
  border-radius: 10px;
  padding: 2px;
  font-family: "Lasting Sketch";
}

.recetteName::placeholder {
  color: #fff;
}

#ingredientList {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  max-height: 75vh;
  scrollbar-width: none;
}

#ingredientList::-webkit-scrollbar {
  display: none;
}

#searchIngCont {
  flex-direction: column;
}

#ingredientList li {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  padding: 8px 0 12px;
  display: flex;
  border-bottom: 2px solid #8c5a251a;
  justify-content: space-between;
}

#ingredientList li:first-child {
  border-bottom: none;
}
#ingredientList li:last-child {
  border-bottom: none;
}

#ingredientList li.totalPateLi {
  border-bottom: none;
  justify-content: flex-end;
}

.ingListPoids {
  color: #8c5a25;
  border: none;
  background: none;
  outline: none;
  display: inline-block;
  text-align: center;
  width: 50px;
}

.ingListPoidsCont {
  padding: 7px 16px 6px;
  flex-grow: 1;
}

.ingListPoidsG {
  color: #8c5a25;
  font-size: 16px;
}

.ingListIcon {
  flex-basis: 40px;
  height: 40px;
  width: 40px;
}

.ingListText {
  padding: 7px 16px 6px;
  color: #8c5a25;
  font-size: 20px;
  flex-basis: 40%;
}

.removeIng {
  flex-basis: 30px;
  height: 30px;
  width: 30px;
  cursor: pointer;
  margin: 5px 0;
}

/* Search bar */
.searchInput {
  width: 80%;
  margin: auto;
  border: none;
  outline: none;
  padding: 10px 16px 6px;
  font-size: 18px;
  border-radius: 5px;
}

#searchList {
  width: 80%;
  padding: 0;
  margin: auto;
  list-style: none;
  background: #edd58d;
}

#searchList li {
  width: 100%;
  padding: 7px 16px 6px;
  color: #8c5a25;
  font-size: 18px;
  cursor: pointer;
}

.nextBtn {
  font-family: "Lasting Sketch";
  padding: 5px 40px;
  border: none;
  outline: none;
  background: #fdeab3;
  color: #8c5a25;
  font-size: 34px;
  border-radius: 8px;
  cursor: pointer;
  position: absolute;
  right: 80px;
  bottom: 60px;
  user-select: none;
}

.nextBtn:hover {
  background: #ffbf4b;
}
