#recetteListCont {
  padding: 0 3vh;
}

#recetteList {
  margin-top: 1.2em;
  background: #fdeab3;
  border-radius: 16px;
  padding: 1em 2em;
}

.recetteListFlexCont {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.listIcon {
  height: 36px;
}

#recetteList .lnkRecetteListTitle {
  font-size: 18px;
  font-weight: 600;
  padding-top: 6px;
  display: inline-block;
  width: 40%;
  color: #8c5a25;
  text-decoration: none;
}

.recetteListButtonCont {
  display: flex;
  width: 20%;
  column-gap: 1em;
  justify-content: right;
}

.newRecetteForm {
  width: 100%;
}

.editRecetteInput {
  width: 100%;
  border: none;
  outline: none;
  background: none;
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  padding-top: 6px;
  color: #8c5a25;
  background-color: #fbf4df;
  border-radius: 6px;
}

.newRecetteInput {
  width: 100%;
  border: none;
  outline: none;
  padding: 0;
  background: none;
  font-size: 18px;
  font-weight: 600;
  padding-top: 6px;
  color: #8c5a25;
}

#recetteList > li {
  width: 100%;
  font-size: 18px;
  padding: 8px 0 12px;
  border-bottom: 2px dashed #8c5a251a;
  justify-content: space-between;
  list-style: none;
  padding: 0.4em 0;
  color: #8c5a25;
}

#recetteList > li:last-child {
  border-bottom: none;
}

.recetteListVersion {
  padding: 0 1em;
}

.recetteListVersion li {
  display: flex;
}

.recetteListVersion a {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  padding: 0;
  color: #8c5a25;
  text-decoration: dashed;
  font-style: italic;
}

.newVersionInput {
  border: none;
  outline: none;
  padding: 0;
  font-size: 18px;
  font-family: "Nunito";
  background: none;
}

/* Base Pate List */
#BasePateSelect {
  border: none;
  margin-top: 1em;
  background: none;
  outline: none;
  border-radius: 8px;
  color: #8c5a25;
  border-top: 1px solid #8c5a2530;
  padding: 0.2em 1em;
}

.basePateIngList {
  width: 80%;
  padding: 0;
  margin: auto;
}

.basePateIngList .ingListPoidsCont {
  flex-grow: 0;
}
