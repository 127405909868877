.art-wrapper {
  height: 100%;
  padding: 0 3vh;
  overflow: auto;
  scrollbar-width: none;
}
.art-wrapper::-webkit-scrollbar {
  display: none;
}

.type-row h2 {
  text-align: center;
  margin-bottom: 0.6em;
}

.art-row {
  width: 75%;
}

.art-row td {
  width: 25%;
  text-align: center;
}

.art-row h3 {
  text-align: center;
}

.art-edit {
  width: 100px;
  margin: auto;
  border: none;
  outline: none;
  display: block;
  text-align: center;
  background: #fff9ed;
  padding: 0.2em;
}

.art-hasPromo {
  width: fit-content;
  margin: auto;
}

.recette-art-wrapper {
  display: flex;
}

.art-tag-cont {
  font-family: "ArchitectsDaughter", serif;
  font-size: 20px;
  background: #fff9ed;
  border-radius: 0.5em;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  border-radius: 0.6rem;
  overflow: hidden;
}

.art-tag-head {
  font-family: "Animatic", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 22px;
  margin: 0;
  text-align: center;
  padding: 0.4rem;
  border-bottom: 1px solid #888;
}

.art-tag-head.art-head-pain {
  background: #f5b54a;
}

.art-tag-head.art-head-viennoiserie {
  background: #ffb79f;
}

.art-tag-head.art-head-biscuit {
  background: #a5e0b6;
}

.art-tag {
  display: flex;
  column-gap: 2vw;
  margin-bottom: 0.6em;
  padding: 0.2em 0.4em;
  justify-content: space-around;
}

.reduc-tag {
  font-size: 16px;
  color: #888;
}

.art-save {
  border: none;
  width: 100%;
  padding: 0.4rem;
  background: #ffe2b1;
  cursor: pointer;
}

.prix-label {
  text-align: center;
  display: block;
}

.promo-label {
  user-select: none;
}

.art-card-item {
  width: 420px;
  border: 8px solid #fff9ed;
  background: #fbe5a6;
  padding: 12px;
}

.art-card-item > * {
  margin-bottom: 0.4em;
}

.btn-save-art {
  display: block;
  margin: auto;
}

.typeName {
  text-transform: capitalize;
}

.barred {
  text-decoration: line-through;
}

.recette-art-cont {
  border-bottom: 1px solid #ebbd3a;
  padding: 1.4em 0;
}
