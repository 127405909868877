.dash-wrapper {
  height: 100%;
  padding: 0 3vh;
  overflow: auto;
  scrollbar-width: none;
}
.dash-wrapper::-webkit-scrollbar {
  display: none;
}

.chart-cont {
  width: 100%;
}
