#BasePateWrapper {
  background: #fdeab3;
  border-radius: 16px;
  padding: 20px;
  margin-right: 3em;
}

#BasePateWrapper h2 {
  color: #8c5a25;
  font-size: 20px;
  font-weight: 600;
  font-family: "Nunito";
  padding: 0.4em 0 0.1em;
}

#BasePateWrapper select {
  margin: auto;
  border: none;
  outline: none;
  padding: 10px 16px 6px;
  font-size: 18px;
  border-radius: 5px;
}

.recetteNote {
  flex: 1 1;
  height: 35vh;
  width: 60%;
  margin-top: 1em;
  background: #edd58d;
  padding: 0.8em;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #8c5a25;
  box-shadow: 0.2em 0.3em 0.5em rgba(0, 0, 0, 0.2);
  border: 4px dotted #8c5a25;
}

/* Base Pate List */
#basePateListCont {
  padding: 0 3vh;
}

#basePateListCont h2 {
  font-size: 18px;
  font-weight: 600;
  padding-top: 6px;
  display: inline-block;
  width: 40%;
  color: #8c5a25;
  text-decoration: none;
}
