.ingredientList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 1.5em;
  row-gap: 2em;
  margin-top: 1.2em;
}

.ing-flexCont {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;
}

#ingListCont {
  padding-left: 2%;
  padding-right: 2%;
  width: 40%;
  overflow-y: scroll;
  scrollbar-width: none;
}

#ingListCont::-webkit-scrollbar {
  display: none;
}

#ing-detCont {
  margin-left: 5%;
  width: 55%;
}

.ingredientItem {
  padding: 1em 0.6em 0;
  border: 0.4em #fff solid;
  border-radius: 0.4em;
  background-color: #ffd78d;
  cursor: pointer;
  box-shadow: 0.1em 0.2em 0.3em rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.ingredientItem.newIngredientItem {
  border: 2px #ffbf4b dotted;
  background: none;
  box-shadow: none;
  opacity: 0.6;
}

.ingredientItem::after {
  content: "";
  background-color: #ffbf4b;
  height: 50%;
  width: 200%;
  display: block;
  position: absolute;
  bottom: -19%;
  border-radius: 50%;
  left: -60%;
  z-index: -1;
}

.ingredientItem:hover {
  background-color: #ffebc2;
}

.ingredientItem.active {
  background-color: #ffebc2;
  border-color: #ffbf4b;
}

.ingredientItem.active::after {
  background-color: #fff;
}

.ingredientItem.active .ingredientName {
  color: #ffbf4b;
}

.ingredientItem.active .ingFournName {
  color: #ffbf4b;
}

.ingFournName {
  padding-bottom: 0.4em;
  text-align: center;
  font-size: 15px;
  color: #fff8e4;
  overflow: hidden;
  text-wrap: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ingredientItem img {
  width: 100%;
  height: auto;
  padding: 0 0.6em;
}

.ingredientItem:hover img {
  animation: pulse 0.6s ease alternate infinite;
}

@keyframes pulse {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
}

.ingredientName {
  margin: 2em 0 0;
  font-weight: 600;
  text-align: center;
  font-family: "Nunito";
  color: #fff;
  font-size: 20px;
}

#ingDetFormCont {
  width: 100%;
  float: right;
  background: #fdeab3;
  border-radius: 16px;
  height: 100%;
  border: 2px dashed #c9ba8d;
  margin-right: 2%;
  position: relative;
}

.delIng {
  position: absolute;
  right: 7px;
  top: 7px;
  width: 3.5em;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.ingNameInputCont {
  position: relative;
}

.ingNameInputFakeBorder {
  padding: 5px;
  border: 1px dashed #555;
  transform: rotate(-2deg);
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 0.15em 0.3em 0.1em rgb(0 0 0 / 20%);
  margin: 1em auto;
  background: #fbf4df;
}

.ingNameInputFake {
  display: block;
  width: fit-content;
  border: none;
  outline: none;
  padding: 10px 16px 6px;
  font-size: 18px;

  border: 1px solid #555;

  min-height: 45px;
  font-weight: 600;
  text-align: center;
  color: transparent;
}

.ingNameInputTrue {
  font-weight: 600;
  transform: rotate(-2deg);
  position: absolute;
  color: #555;
  font-size: 18px;
  top: 5px;
  left: 0;
  width: 100%;
  height: 50px;
  border: none;
  background: none;
  text-align: center;
  outline: none;
  user-select: none;
}

.ingIconWrapper {
  width: 16%;
  display: block;
  margin: auto;
  position: relative;
}

.ingIcon {
  width: 100%;
  display: block;
  margin: auto;
}

.ingIconInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  cursor: pointer;
}

.saveBtn {
  font-family: "Lasting Sketch";
  padding: 5px 40px;
  border: none;
  outline: none;
  background: #fdeab3;
  color: #8c5a25;
  font-size: 34px;
  border-radius: 8px;
  cursor: pointer;
  display: block;
  float: right;
  margin-top: 40px;
  position: absolute;
  right: 2em;
  bottom: 20px;
  user-select: none;
  border: 2px dashed #c9ba8d;
}

.saveBtn:hover {
  background: #ffbf4b;
  border: 2px solid #ffbf4b;
}

.ingFournisseurInput {
  display: block;
  border: none;
  outline: none;
  padding: 0 1.5em 6px;
  border-radius: 5px;
  font-family: "Nunito";
  font-size: 21px;
  background: none;
  color: #8c5a25;
  font-style: italic;
  font-weight: 600;
  text-align: center;
  width: 100%;
}

.ingFournInpCont:last-child .ingFournInpBorder {
  border: none;
}

.ingFournInpBorder {
  border-bottom: 1px solid #8c5a2533;
  display: block;
  width: 80%;
  margin: 0.6em auto;
}

.ingFournInpLabel {
  display: block;
  font-family: "Nunito";
  color: #8c5a25;
  font-style: italic;
  font-weight: 600;
}

.ingFournInp {
  background: none;
  border: none;
  outline: none;
  color: #8c5a25;
  font-family: "Nunito";
  display: block;
  width: 100%;
}

#ingDetFormWrapper {
  padding: 2em 3em;
}

.ingFournCont {
  height: 55vh;
  display: flex;
  padding: 2em 3em;
  border-top: 2px dashed #c9ba8d;
}

.ingFournWrapper {
  flex: 2 1;
  height: 100%;
}

.ingFournisseurScroll {
  overflow-y: scroll;
  scrollbar-width: none;
  height: 94%;
}

.ingFournisseurScroll::-webkit-scrollbar {
  display: none;
}

.ingFournNote {
  flex: 1 1;
  height: 35vh;
  /*aspect-ratio: 1 / 1; */
  background: #edd58d;
  padding: 0.8em;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #8c5a25;
  box-shadow: 0.2em 0.3em 0.5em rgba(0, 0, 0, 0.2);
  border: 4px dotted #8c5a25;
}

.ingFournNoteInput {
  border: none;
  outline: none;
  background: none;
  color: #8c5a25;
  line-height: 2;
  width: 100%;
  font-weight: 600;
  font-family: "Nunito";
  resize: none;
  line-height: 18px;
  height: 100%;
}

.ingFournNoteImg {
  width: 100%;
  height: auto;
}
