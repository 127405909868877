.cmd-wrapper {
  height: 100%;
  padding: 0 3vh;
}

.cmd-list {
  padding: 0;
  height: 90%;
  overflow-y: scroll;
  scrollbar-width: none;
}

.cmd-list::-webkit-scrollbar {
  display: none;
}

.commande-list > li {
  padding: 0.6vh 0;
  border-bottom: 1px solid #f7c842;
}

.commande-list > li:last-child {
  border-bottom: none;
}

.cmd-list .commandeHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  list-style: none;
}

.dateHead {
  margin-top: 3vh;
}

.cmd-dateHead {
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

.cmd-client {
  font-size: 22px;
  padding: 5px 0px;
  cursor: pointer;
  user-select: none;
}

.cmdHeadBtnCont {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.commande-list {
  padding: 0;
  list-style: none;
}

.cmd-btn {
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
}

.cmd-btn-yes {
  background: #c3d982;
}

.cmd-btn-no {
  background: #fd9e58;
}
