.rct-wrapper {
  height: 100%;
  padding: 0 3vh;
  overflow: auto;
  scrollbar-width: none;
}
.rct-wrapper::-webkit-scrollbar {
  display: none;
}

.rct-form {
  display: flex;
  flex-direction: column;
  max-width: 60%;
}

.rct-form textarea {
  resize: vertical;
}

.rct-form label {
  user-select: none;
}
